// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}





































































































































































































































































































































































































































































































































































































































.proposal-form-documents {
  &.tab-4 {
    button {
      margin: 0 ;
    }

    .proposal-form-documents-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .form-item {
      display: unset;
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .button + .button {
        margin-left: 10px!important;
      }
    }

    &.complete {
      .proposal-form-documents-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        [aria-labelledby="cert"] {
          margin: 45px 0;
        }

        button {
          padding: 0;
          margin: 0;
          border: none;
          background: none;
        }

        .special-button{
              background: #6f61e9;
              border: 1px solid #6f61e9;
              color: #fff;
              padding: 0 20px;
        }

        .link,
        a {
          color: #1e72c2;
          text-decoration: none;
          cursor: pointer;
        }

        p {
          width: 100%;
          margin: 0;
          text-align: center;
          font-style: normal;
          font-size: 16px;
          line-height: 25px;
          color: #000;
        }
      }
    }
  }
}
.upd-switch
{
  vertical-align: middle;

}
.upd-switch-item{
  margin-right: 10px;
}

.file-loader {
  width: 260px;
  margin-left: 0 !important;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font-size:0;
  }
}
