// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}






























































































































































































































































































































































































































































.result-price {
  padding-top: 25px;
  border-top: 1px dashed #e0dfee;
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  margin-top: 50px;
  color: #000;
}

.product-form {
  width: 900px;
  margin: 0 auto;

  &-header {
    text-align: center;
    margin-bottom: 30px;
  }

  &__title {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }

  &-short-info {
    font-size: 16px;
    line-height: 24px;
  }

  &-table {
    width: 100%;
    border-spacing: 0;
    overflow: hidden;

    tr {
      td {
        position: relative;
        padding: 15px;
        text-align: left;
        height: auto;
        border-style: solid;
        border-width: 1px 0 0;
        border-color: #e0dfee;

        &:first-child {
          width: 120px;
          border-left-width: 1px;
          padding-left: 25px;
        }

        &:nth-child(2) {
          width: auto;
        }

        &:last-child {
          text-align: end;
          height: 100%;
          width: 140px;
          padding-right: 25px;
          border-right-width: 1px;
        }
        
      }
    }

    thead {
      background: #f9fafc;

      td {
        color: rgba(98, 92, 149, 0.8);
        font-size: 14px;
        font-weight: 700;

        &:last-child {
          border-top-right-radius: 8px;
        }

        &:first-child {
          border-top-left-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        &:last-child {
          td {
            border-bottom-width: 1px;

            &:last-child {
              border-bottom-right-radius: 8px;
            }

            &:first-child {
              border-bottom-left-radius: 8px;
            }
          }
        }
      }
    }

    .product-form-table-item {
      color: #333;

      .form-switch {
        vertical-align: middle;
      }

      &_even {
        background: #f9fafc;
      }

      &_disabled{
        opacity: 0.5;
      }

      &__indicator-subitems {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 12px;
        top: 50%;
        border: 0;
        padding: 0;
        background: none;
        margin-top: -6px;

        &::before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border: 0px solid rgba(98, 92, 149, 0.5);
          border-width: 0 2px 2px 0px;
          transform: rotate(45deg);
          transition: transform 200ms ease;
        }

        &.is-active {
          &::before {
            transform: rotate(225deg);
          }
        }
      }

      &-subitems {
        &.is-hidden {
          td {
            border-top-width: 0;
          }
        }

        &.is-animation {
          td {
            border-top-width: 1px !important;
          }

          & + tr td {
            border-top-color: #e0dfee !important;
          }
        }

        td {
          padding: 0;
        }
      }

      &_required {
        .product-form-table-item__checkbox,
        .product-form-table-item__name,
        .product-form-table-item__price {
          & > * {
            opacity: 0.7;
          }
        }
      }

      &_selected {
        & + .product-form-table-item-subitems,
        & + .product-form-table-item {
          td {
            border-top-color: #6f61e9;
          }
        }

        td {
          color: #6f61e9;
          border-color: #6f61e9;
        }

        .product-form-table-item__price {
          color: #6f61e9;
        }

        .product-form-table-item__name {
          color: #6f61e9;
          font-weight: 700;
        }
      }

      &__name {
        font-size: 14px;
        color: #333;
        transition: 0.2s ease;
        transition-property: color;
      }

      &__subitems {
        overflow: hidden;
        height: 0;


        &-list {
          padding: 25px 15px;
        }

        .radio-group {
          padding: 20px 25px;
        }

        .form-item-checkbox {
          & > label {
            width: 100%;
          }

          & + .form-item {
            margin-top: 15px;
          }
        }
      }

      &__price {
        font-size: 18px;
        font-weight: 700;
        color: rgba(51, 51, 51, 0.8);
        transition: 0.2s ease;
        transition-property: color;
        text-align: end;
      }
    }
  }
}

.next-tab-button-wrapper {
  position: relative;
  .tooltip {
    position: absolute;
    top: 50%;
    right: -5px;
    z-index: 1;
    transform: translate(100%, -50%);
    padding: 10px 15px 10px 10px;
    width: 220px;
    background: #6f61e9;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    color: #fff;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: -7px;
      margin-top: -9px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 8px solid #6f61e9;
    }
    span:nth-child(2) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
