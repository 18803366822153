// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.ie .form-header-top .title {
  margin-right: auto;
}

.type-entre {
  margin-bottom: 16px;
  &[touched] {
    border: 1px solid #ff7b7b;
    &[valid]{
      border: none;
    }
  }
  .form-item-radio {
    width: 100%;
    justify-content: center;
  }
}

.proposal-form {
  width: 900px;
  margin: 0 auto;

  .button_file {
    position: relative;

    label {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  .product-list {
    margin-top: 35px;
  }

  .result-price {
    margin-top: 45px;
    padding: 20px 0 10px;
    border-top: 1px dashed #e0dfee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    font-weight: normal;
    font-size: 21px;
    line-height: 26px;
    &__title {
      text-align: left;
    }
    &__current {
      text-align: right;
    }
  }
}

.form-header {
  margin-bottom: 25px;
  &-top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 5px;
      border-bottom: 1px dashed #cccccc;
    }
    & > div {
      position: relative;
      background: #fff;
      z-index: 1;
    }
    .title {
      position: relative;
      font-size: 25px;
      color: #000;
    }

    .icons {
      position: absolute;
      bottom: 4px;
      left: -10px;
      transform: translate(-100%, 0);
      display: flex;
      align-items: center;

      svg {
        & + svg {
          margin-left: 10px;
        }
        path {
          fill: #6f61e9;
        }
      }
    }

    .status {
      font-size: 16px;
      text-align: right;
      color: #333;
      display: flex;
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    ul {
      font-size: 14px;
      line-height: 24px;
      color: #808080;
      list-style-type: none;
      padding: 0;
      margin: 0;
      span {
        font-weight: 500;
      }
    }

    .applicant {
      text-align: end;
    }
  }

  .sand-clock-icon {
    display: inline-block;
    padding: 0 5px;
    font-size: 20px;
    animation:spin 1s linear infinite;
  }
  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
}

.next-tab-button-wrapper {
  position: relative;
  .tooltip {
    position: absolute;
    top: 50%;
    right: -5px;
    z-index: 1;
    transform: translate(100%, -50%);
    padding: 10px 15px 10px 10px;
    width: 220px;
    background: #6f61e9;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    color: #fff;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: -7px;
      margin-top: -9px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 8px solid #6f61e9;
    }
    span:nth-child(2) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

